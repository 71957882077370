import { Box} from "@mui/material";
import { useState } from "react";
import Title from "../text/Title";
import Paragraph from "../text/Paragraph";
import SuccessDialog from "../dialogBox";
import ContactFormFields from "./formFields";
import { FormValues } from "../../types/type";
import "./style.scss";

const ContactForm = () => {
  const [open, setOpen] = useState(false);

  const handleFormSubmit = (data: FormValues) => {
    const url = import.meta.env.VITE_POSTAPI;
    fetch(url, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify(data) 
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setOpen(true);
    })
    .catch(error => {
      console.error('Error:', error); 
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className="contact-container" sx={{ padding: { xs: "8% 5%", md: "8% 20%" } }}>
      <Title text={"contact"} textAlign="center" />
      <Paragraph text="We bring a breadth of experience and expertise. It’s important to us that you can count on us at any stage of the process and beyond." textAlign="center" />
        <ContactFormFields onSubmit={handleFormSubmit} />
        <SuccessDialog 
        open={open} 
        onClose={handleClose} 
      />
    </Box>
  );
};

export default ContactForm;
