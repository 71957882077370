import React from 'react';
import { Drawer, Box, List, ListItemButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import Title from '../../text/Title';
import logoWhite from "../../../assets/logo/Zumar Logo White.png"
import "./style.scss"
//icon
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';

interface DrawerComponentProps {
  open: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const BlurredBackground = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  zIndex: theme.zIndex.drawer - 1,
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '25%',
    },
  },
}));

const ResponsiveLogo = styled('img')(({ theme }) => ({
  marginTop: "10%",
  maxWidth: '60%',
  height: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '65%',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '55%',
  },
}));

const DrawerComponent: React.FC<DrawerComponentProps> = ({ open, onClose }) => {
  const location = useLocation();

  const itemList = [
    { text: "Home", to: "/" },
    { text: "About", to: "/about" },
    { text: "Services", to: "/services" },
    { text: "projects", to: "/projects" },
    { text: "Contact", to: "/contact" }
  ];
  

  const drawerContent = (
    <Box className="drawer-content" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} onClick={(event) => onClose(event)}>
        <IconButton>
          <CloseIcon fontSize='large' sx={{ color: "#fff" }} />
        </IconButton>
      </Box>
      <Box className='drawer-items' sx={{gap:{md:"10%",xs:"5%"}}}>
        <ResponsiveLogo
          src={logoWhite}
          alt="Logo"
        />
        <List className='page-list'>
          {itemList.map((item) => {
            const { text, to } = item;
            const isActive = location.pathname === to;
            return (
              <ListItemButton
                key={text}
                component={RouterLink}
                to={to}
                onClick={(event) => {
                  onClose(event); 
                  window.scrollTo(0, 0);
                }}
                sx={{
                  color: isActive ? '#fff' : "grey",
                  "&:hover": {
                    color: '#fff',
                  }
                }}
              >
                <Title text={text} fontWeight={"bolder"}/>
              </ListItemButton>
            )
          })}
        </List>
        <Box>
          <Box className="flex-row-center">
            <IconButton href="+91 9445442006" sx={{ color: 'grey' }}>
              <PhoneIcon />
            </IconButton>
            <Title text={"+91 9445442006"} color={"grey"} fontSize={"18px"} fontWeight={"0"} />
          </Box>
          <Box className="flex-row-center">
            <IconButton href="info@zumarec.com" sx={{ color: 'grey' }}>
              <MailIcon />
            </IconButton>
            <Title text={"info@zumarec.com"} color={"grey"} fontSize={"18px"} fontWeight={"lighter"} />
          </Box>
        </Box>
        <Box className="flex-row-center" sx={{ mt:{xs:"12%", md:"20%"} }}>
          <IconButton href="https://in.linkedin.com/company/zumar-engineering-and-constructions" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 50 50"
              style={{ fill: "grey" }}
            >
              <path d="M 8 3.0097656 C 4.53 3.0097656 2.0097656 5.0892187 2.0097656 7.9492188 C 2.0097656 10.819219 4.59 12.990234 8 12.990234 C 11.47 12.990234 13.990234 10.870625 13.990234 7.890625 C 13.830234 5.020625 11.36 3.0097656 8 3.0097656 z M 3 15 C 2.45 15 2 15.45 2 16 L 2 45 C 2 45.55 2.45 46 3 46 L 13 46 C 13.55 46 14 45.55 14 45 L 14 16 C 14 15.45 13.55 15 13 15 L 3 15 z M 18 15 C 17.45 15 17 15.45 17 16 L 17 45 C 17 45.55 17.45 46 18 46 L 27 46 C 27.552 46 28 45.552 28 45 L 28 30 L 28 29.75 L 28 29.5 C 28 27.13 29.820625 25.199531 32.140625 25.019531 C 32.260625 24.999531 32.38 25 32.5 25 C 32.62 25 32.739375 24.999531 32.859375 25.019531 C 35.179375 25.199531 37 27.13 37 29.5 L 37 45 C 37 45.552 37.448 46 38 46 L 47 46 C 47.55 46 48 45.55 48 45 L 48 28 C 48 21.53 44.529063 15 36.789062 15 C 33.269062 15 30.61 16.360234 29 17.490234 L 29 16 C 29 15.45 28.55 15 28 15 L 18 15 z"></path>
            </svg>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {open && <BlurredBackground />}
      <StyledDrawer
        anchor="right"
        open={open}
        onClose={onClose}
      >
        {drawerContent}
      </StyledDrawer>
    </>
  );
};

export default DrawerComponent;