import Paragraph from "../text/Paragraph"
import { Box, Typography } from "@mui/material"

interface BlockComponentProps {
    title?: string,
    paragraph: string,
    bgColor?: string,
    textAlign?:'left' | 'center' | 'right'
}

const BlockContent: React.FC<BlockComponentProps> = ({ title, paragraph, bgColor,textAlign }) => {
    return (
        <Box sx={{ p:{xs:"5% 6%", md:"5% 22%"}, backgroundColor: bgColor }}>

            <Typography
                sx={{
                    textAlign: textAlign || "center",
                    textTransform: "uppercase",
                    fontWeight: "600",
                    fontFamily: "Lemon Milk",
                    fontSize: { xs: "1.75rem", sm: "2.2rem", md: "2.7rem" },
                    marginY:3
                }}>
                {title}
            </Typography>
            <Paragraph text={paragraph} textAlign={textAlign || "center"} />
        </Box>
    )
}

export default BlockContent;