import { Box } from "@mui/material"
import { Slab } from "react-loading-indicators"

const Loader =() =>{
    return(
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          height="100vh" 
          width="100vw"
        >
          <Slab color="#000000" size="large" text="loading" textColor="" />
        </Box>
    )
}

export default Loader;